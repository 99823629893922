import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'


import vSelect from 'vue-select'
Vue.component('v-select', vSelect)


import VButton from '@/@core/components/v-button/VButton.vue'
import VCardLogin from '@/views/components/CardLogin/CardLogin.vue'
const VCardContent = () => import('@/views/components/CardLogin/CardContent.vue')
import VSelectField from '@/components/VSelectField/VSelectField.vue'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('v-button', VButton)
Vue.component('v-card-login', VCardLogin)
Vue.component('v-card-content', VCardContent)
Vue.component('v-select-field', VSelectField)
