const POSITION_NOTIFICATION = 'bottom-right'
const TIMEOUT_NOTIFICATION = 8000

export default {
    install(Vue) {
        Vue.prototype.$notification = function (notificationOptions, self, component) {
            if (notificationOptions.length > 1) {
                notificationOptions.forEach(element => {
                    self.$toast({
                        component: component,
                        props: {
                            icon: "AlertCircleIcon",
                            text: element.Message,
                            title: element.Title,
                            variant: "success",
                        },

                    }, {
                        position: POSITION_NOTIFICATION,
                        timeout: TIMEOUT_NOTIFICATION
                    });
                })
            } else if (notificationOptions.response.data.message !== undefined && notificationOptions.response.data.message.length >= 1) {


                notificationOptions.response.data.message.forEach((n) => {
                    self.$toast({
                        component: component,
                        props: {
                            icon: "XCircleIcon",
                            text: n,
                            title: 'Erro',
                            variant: "danger",
                        }
                    }, {
                        position: POSITION_NOTIFICATION,
                        timeout: TIMEOUT_NOTIFICATION
                    });
                })

            } else {
                self.$toast({
                    component: component,
                    props: {
                        icon: "AlertCircleIcon",
                        text: notificationOptions[0].Message,
                        title: notificationOptions[0].Title,
                        variant: notificationOptions[0].Color,
                    }
                }, {
                    position: POSITION_NOTIFICATION,
                    timeout: TIMEOUT_NOTIFICATION
                });
            }

        }
    }
}