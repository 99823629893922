export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/Login/Index.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/esqueceu-senha',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/EsqueceuSenha/Index.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/redefinir-senha',
    name: 'auth-reset-password',
    component: () => import('@/views/pages/RedefinirNovaSenha/Index.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
]
