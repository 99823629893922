import { required, email, confirmed } from 'vee-validate/dist/rules'
import {  extend } from 'vee-validate'

extend('required', {
    ...required,
    message: field => `Campo ${field} é obrigatório`
})

extend('email', {
    ...email,
    message: field => `${field} inválido`
})

extend('confirmed', {
    ...confirmed,
    message: () => 'As senhas não coincidem'
})