import http from '../../axios'
import session from '../../../plugins/session'

export const setEmpresaPorUrl = (url) => {
    return new Promise((resolve, reject) => {
        http.get(`/clientes/obterClientePorUrl/${url}`)
        .then((response) => {
            const { guidCliente, url, logoUrl, idCliente, vigenciaInicial, vigenciaFinal, quantidadeSalas, quantidadeEventos } = response.data
            if (guidCliente && url) {
                const empresa = {
                  guidCliente,
                  url,
                  logoUrl,
                  idCliente,
                  vigenciaInicial,
                  vigenciaFinal,
                  quantidadeSalas,
                  quantidadeEventos
                } 
                session.start()
                session.set('empresa', empresa)
                resolve(response)
              } else {
                session.destroy()
              }
    
        }).catch((error) => {
            reject(error)
        })
    })
}