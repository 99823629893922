<template>
  <validation-provider :rules="rules" :name="name" #default="{ errors }">
    <b-form-group :label="label" id="form-group" :class="styleLabel">
      <v-select
        :label="itemKey"
        v-model="m_value"
        :options="m_items"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :placeholder="placeholder"
      >
        <div slot="no-options">{{ noOptions }}</div>
      </v-select>
      <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

import { BFormGroup, BFormSelect } from "bootstrap-vue";

export default {
  components: {
    BFormGroup,
    ValidationProvider,
    BFormSelect,
  },
  props: {
    items: {
      type: Array,
      default: [],
    },
    value: {
      type: null,
    },
    itemKey: {
      type: String,
      default: "",
    },
    itemValue: {
      type: String,
      default: "",
    },
    name: {
      type: String,
    },
    rules: {
      type: [String, Object, null],
      default: "",
    },
    text: {
      type: String,
    },
    label: {
      type: String,
    },
    noOptions: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    labelClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      m_items: [],
      m_value: "",
    };
  },
  computed: {
    styleLabel() {
      return this.labelClass;
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(val) {
        this.m_items = val;
      },
    },
    value: {
      immediate: true,
      handler(val) {
        this.m_value = val;
      },
    },
    m_value(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
.col-form-label {
  padding-bottom: calc(0.1rem + 1px) !important;
}

.form-group  {
  font-size: 16px !important;
}
</style>
