<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div :class="lengthScreen.width < breakPoint ? 'tamanho-card' : 'auth-inner'">
      <b-card class="mb-0" style="min-height: 505px">
        <b-row>
          <b-col cols="6" style="padding: 0px !important">
            <div
              :class="
                lengthScreen.width < breakPoint
                  ? 'bg-color-card-none'
                  : 'bg-color-card'
              "
            >
              <img :src="caminho" height="60" />
            </div>
          </b-col>
          <slot />
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>

import { BCard, BRow, BCol } from 'bootstrap-vue'

import { breakPointScreen } from '@/plugins/contantes'

export default {
  components: {
      BCard,
      BRow,
      BCol
  },
  props : {
      caminho: {
          type: String,
          default: ''
      }
  },
  data() {
    return {
      lengthScreen: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    breakPoint () {
      return breakPointScreen("md")
    }
  },
  methods: {
    getLengthScreen() {
      this.lengthScreen.width = window.innerWidth;
      this.lengthScreen.height = window.innerHeight;
    },
  },
  created() {
    window.addEventListener("resize", this.getLengthScreen);
    this.getLengthScreen();
  },
  destroyed() {
    window.addEventListener("resize", this.getLengthScreen);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.card-body {
  padding: 0px !important;
}

.bg-color-card {
  background-color: #eff2f7 !important;
  height: 505px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-color-card-none {
  display: none;
}

.tamanho-card {
  width: 400px;
}
</style>