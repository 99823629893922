export const GET_URL = () => {
    let empresaUrl = 'programador'
    if (!window.location.hostname.includes('localhost')) empresaUrl = window.location.hostname.split('.')[0]
    return empresaUrl
  }
  
  export const caminhoLogo = () => {
    return require('@/assets/logo.png')
  }

  export const breakPointScreen = (tipo) => {
    const cases = {
      'sm': 600,
      'md': 810
    }

    return cases[tipo]
  }