import router from '@/router'
import axios from 'axios'
import { events } from '@/plugins/events'

import { getStorage } from '@/shared/userStorage'

const http = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API
})

const expired = [
    {
        Color: 'warning',
        Icon: '-check',
        IconPack: 'feather',
        Key: 'UsuarioOSessaoExpiradauSenhaInvalidos',
        Message: 'SessaoExpirada',
        Time: 3000,
        Title: 'Atenção'
    }

]

http.interceptors.request.use((config) => {
    const token = getStorage('accessToken')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

http.interceptors.response.use((response) => {
    return response
}, (error) => {
   
    const { response } = error
    if (response && response.status === 401) {

        router.push('/login-sessao')
        return Promise.reject(expired)

    } else if (response.status === 400 && response.data[0] !== undefined) {
        
        return Promise.reject(response.data)
        
    }
    return  Promise.reject(error)
})



export default http