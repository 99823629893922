<template>
  <b-button
    v-if="type !== ''"
    class="mb-0"
    :variant="getColor"
    :disabled="disabled"
    :block="blocked"
    type="submit"
  >
    <template>
      <b-spinner v-if="carregando" small></b-spinner>
      {{ label }}
    </template>
  </b-button>
  <b-button
    v-else
    class="mb-0"
    @click.prevent="onSubmit"
    :variant="getColor"
    :disabled="disabled"
    :block="blocked"
  >
    <template>
      <b-spinner v-if="carregando" small></b-spinner>
      {{ label }}
    </template>
  </b-button>
</template>

<script>
import { defineComponent, computed, reactive } from "@vue/composition-api";

import { BButton, BSpinner } from "bootstrap-vue";

export default defineComponent({
  components: {
    BButton,
    BSpinner,
  },
  props: {
    label: {
      type: String,
      default: "label",
    },
    color: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icone: {
      type: String,
      default: "Plus",
    },
    carregando: {
      type: Boolean,
      default: false,
    },
    useIcone: {
      type: Boolean,
      default: true,
    },
    blocked: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const lengthScreen = reactive({
      width: 0,
      height: 0,
    });

    function getLengthScreen() {
      lengthScreen.width = window.innerWidth;
      lengthScreen.height = window.innerHeight;
    }

    function getIcon() {
      return `${props.icone}Icon`;
    }

    const getColor = computed(() => {
      let color = ""

      if (props.outline) {
        color = `outline-${props.color}`
      } else {
        color = `${props.color}`
      }

      return color
    })

    return {
      lengthScreen,
      getLengthScreen,
      getIcon,
      getColor
    };
  },
  methods: {
    onSubmit() {
      this.$emit("onSubmit");
    },
  },
  created() {
    window.addEventListener("resize", this.getLengthScreen);
    this.getLengthScreen();
  },
  destroyed() {
    window.addEventListener("resize", this.getLengthScreen);
  },
});
</script>
